import logo from "../assets/images/logo.png";
import morthLogo from "../assets/images/morth.png";
import loginBG from "../assets/images/login_bg.jpg";
import rgbLogo from "../assets/images/rgb_logo.jpg";

export const AppImages = {
  logo,
  morthLogo,
  loginBG,
  rgbLogo,
};
